import React, { useState, useEffect } from "react"
import { Box, 
   Link, 
   Text, 
   Heading,
   Image,
   Button,
   Center,
   Spinner,
   WrapItem,
   Wrap,
   Spacer,
   Tooltip
} from "@chakra-ui/react"
import { Link as GatsbyLink } from 'gatsby'
import axios  from 'axios'
import Layout from '../components/layout.js';
import {num2ja} from '../util/functions.js';
import {
   getWindowParam,
   AutoBrLinkMoreParser,
   Midasi4v1,
   Midasi5,
} from '../util/functions.js';

const VideoDetail = (pageprops) => {
   const [params,setParams] = useState({
      vi:""
   });
   const [videodetail,setvideodetail] = useState({
      videodetail:[],
      videoChannel:[],
      videothumbnailUrl:"",
      channelthumbnailUrl:"",
      finished:false,
   });
   const [channelwords,setchannelwords] = useState([]);
   const [channelkanrenWords,setchannelkanrenWords] = useState({
      kanrenWords:[],
      finished:false
   });
   const [show, setShow] = useState(false)
   const [kanrenPosts,setkanrenPosts] = useState({
      kanrenPosts:[],
      finished:false
   });

   const getVideoDetails = (param) => {
      setvideodetail({
         videodetail:[],
         videoChannel:[],
         videothumbnailUrl:"",
         channelthumbnailUrl:"",
         finished:false,
      });
      var type="getVideoDetails";
      let postdata = Object.assign({type:type}, param);
      axios.post('https://ytsearch-wordpress.yt-search.net/api/', postdata)
         .then( (res) => {
            // console.log(res);
            setvideodetail({
               videodetail:res.data.video,
               videoChannel:res.data.channel,
               videothumbnailUrl:res.data.trimedvi,
               channelthumbnailUrl:res.data.trimedch,
               finished:true,
            })
            getChannelKanrenWordsFromCh({
               ch:res.data.channel[0].channelId
            })
         })
         .catch( (error) => {
            console.log(error);
            setvideodetail({
               videodetail:[],
               videoChannel:[],
               videothumbnailUrl:"",
               channelthumbnailUrl:"",
               finished:true,
            })
      });
   }

   const getChannelKanrenWordsFromCh = (param) => {
      setchannelkanrenWords({
         kanrenWords:[],
         finished:false
      });
      var type="getChannelKanrenWordsFromCh";
      let postdata = Object.assign({type:type}, param);
      axios.post('https://ytsearch-wordpress.yt-search.net/api/', postdata)
         .then( (res) => {
            // console.log(res.data);
            var pairs = Object.entries(res.data.wordresult);
            pairs.sort(function(p1, p2){
               var p1Val = p1[1], p2Val = p2[1];
               return p2Val - p1Val;
            })
            setchannelkanrenWords({
               kanrenWords:pairs,
               finished:true
            });
         })
         .catch( (error) => {
            console.log(error);
            setchannelkanrenWords({
               kanrenWords:[],
               finished:true
            });
      });
   }

   const getVideoKanrenPostsFromVi = (param) => {
      setkanrenPosts({
         kanrenPosts:[],
         finished:false
      });
      var type="getVideoKanrenPostsFromVi";
      let postdata = Object.assign({type:type}, param);
      axios.post('https://ytsearch-wordpress.yt-search.net/api/', postdata)
         .then( (res) => {
            console.log(res.data.postresult);
            setkanrenPosts({
               kanrenPosts:res.data.postresult,
               finished:true
            });
         })
         .catch( (error) => {
            console.log(error);
            setkanrenPosts({
               kanrenPosts:[],
               finished:true
            });
      });
   }

   const catnemes = 
   {
      //YouTUBEに存在するカテゴリ
      1 : "映画とアニメ",
      2 : "自動車と乗り物",
      10 : "音楽",
      15 : "ペットと動物",
      17 : "スポーツ",
      18 : "ショート ムービー",
      19 : "旅行とイベント",
      20 : "ゲーム",
      21 : "動画ブログ",
      22 : "ブログ",
      23 : "コメディー",
      24 : "エンターテイメント",
      25 : "ニュースと政治",
      26 : "ハウツーとスタイル",
      27 : "教育",
      28 : "科学と技術",
      29 : "非営利活動" ,
      30 : "映画",
      31 : "アニメ",
      32 : "アクション/アドベンチャー",
      33 : "クラシック",
      34 : "コメディー",
      35 : "ドキュメンタリー",
      36 : "ドラマ",
      37 : "家族向け",
      38 : "海外",
      39 : "ホラー",
      40 : "SF/ファンタジー",
      41 : "サスペンス",
      42 : "短編",
      43 : "番組",
      44 : "予告編",
   };

   useEffect(() =>{
      let temp = getWindowParam(params);
      setParams(temp);
      getVideoDetails(temp);
      getVideoKanrenPostsFromVi(temp);
   },[]);


   return (
      <Layout 
         title={"ビデオ詳細"}
         description={"YouTubeビデオの詳しい数値を掲載しています"}
         // image={}
         uri={pageprops.uri}
         canonicalUri={"/videodetail"}
      >
         {videodetail.finished ?
            videodetail.videodetail.length > 0 ?
               <>
               <Midasi4v1 mb="0.5rem" ch={params.vi}>{videodetail.videodetail[0].videoTitle}</Midasi4v1>
               <Center>
                  <Image rounded="lg" boxSize="45%"  src={videodetail.videothumbnailUrl} />
               </Center>

               <Midasi5>チャンネル</Midasi5>
               <Box bg="#fff" mx="0.75rem" p="1rem">
                  {/* <Box bg="#fff" mx="0.75rem"> */}
                  <Center>
                     <Link
                        as={GatsbyLink} 
                        to={"/channeldetail"
                           + "?ch=" + videodetail.videoChannel[0].channelId}
                        fontWeight={"bold"}
                     >
                        <Heading size="lg">{videodetail.videoChannel[0].channelTitle}</Heading>
                     </Link>
                  </Center>
                  <Center m="1rem">
                     <Image rounded="md" src={videodetail.channelthumbnailUrl}  boxSize="25%"/>
                  </Center>
                  <Wrap spacing="0" fontSize="1rem" mt="0.5rem" borderBottom="1px #d9d9d9b8 solid">
                     <WrapItem>チャンネル登録者数 : </WrapItem>
                     <Spacer />
                     <WrapItem>{num2ja(videodetail.videoChannel[0].registCount)}人</WrapItem>
                  </Wrap>
                  <Wrap spacing="0" fontSize="1rem" mt="0.5rem" borderBottom="1px #d9d9d9b8 solid">
                     <WrapItem>再生回数 : </WrapItem>
                     <Spacer />
                     <WrapItem>{num2ja(videodetail.videoChannel[0].viewsCount)}回</WrapItem>
                  </Wrap>
                  <Wrap spacing="0" fontSize="1rem" mt="0.5rem" borderBottom="1px #d9d9d9b8 solid">
                     <WrapItem>動画数 : </WrapItem>
                     <Spacer />
                     <WrapItem>
                        {videodetail.videoChannel[0].videosCount + "本"}
                     </WrapItem>
                  </Wrap>
                  <Wrap spacing="0" fontSize="1rem" mt="0.5rem" borderBottom="1px #d9d9d9b8 solid">
                     <WrapItem>YouTube登録日 : </WrapItem>
                     <Spacer />
                     <WrapItem>{videodetail.videoChannel[0].publishedAt}</WrapItem>
                  </Wrap>
                  <Wrap spacing="0" fontSize="1rem" mt="0.5rem" borderBottom="1px #d9d9d9b8 solid">
                     <WrapItem>人柄・属性 : </WrapItem>
                     <Spacer />
                     <WrapItem>{videodetail.videoChannel[0].myStatue}</WrapItem>
                  </Wrap>
                  <Wrap spacing="0" fontSize="1rem" mt="0.5rem" borderBottom="1px #d9d9d9b8 solid">
                     <WrapItem>内容 : </WrapItem>
                     <Spacer />
                     <WrapItem>{videodetail.videoChannel[0].myCategory}</WrapItem>
                  </Wrap>
                  <Wrap spacing="0" fontSize="1rem" mt="0.5rem">
                     <WrapItem>タグ・キーワード : </WrapItem>
                     <Spacer />
                     <WrapItem>
                        <Wrap>
                        {channelkanrenWords.finished ? 
                           channelkanrenWords.kanrenWords.length > 0 ?
                           React.Children.toArray(channelkanrenWords.kanrenWords.map(val => {
                              let key = val[0];
                              return (
                                 <>
                                 <WrapItem>
                                    <Tooltip hasArrow label={val[1]}>
                                    <Button 
                                       colorScheme="facebook"
                                       borderRadius="0.5rem"
                                       fontSize="0.7rem"
                                       ml="0.3rem"
                                       mt="0.3rem"
                                       px="0.5rem"
                                       height="1.5rem"
                                       as={GatsbyLink} 
                                       // as={Link} 
                                       to={"/videolist"
                                          + "?keyword=" + encodeURIComponent(key)}
                                    >
                                       {key}
                                    </Button>
                                    </Tooltip>
                                 </WrapItem>
                                 </>
                              )
                           }))
                           :
                           ""
                        :
                        ""
                        }
                        </Wrap>
                     </WrapItem>
                  </Wrap>
                  {/* </Box> */}
               </Box>

               <Midasi5>概要</Midasi5>
               <Box bg="#fff" mx="0.75rem" p="1rem">
                  {/* <Box bg="#fff" mx="0.75rem"> */}
                  <Wrap spacing="0" fontSize="1rem" mt="0.5rem" borderBottom="1px #d9d9d9b8 solid">
                     <WrapItem>再生回数 : </WrapItem>
                     <Spacer />
                     <WrapItem>{num2ja(videodetail.videodetail[0].viewsCount)}回</WrapItem>
                  </Wrap>
                  <Wrap spacing="0" fontSize="1rem" mt="0.5rem" borderBottom="1px #d9d9d9b8 solid">
                     <WrapItem>動画投稿日 : </WrapItem>
                     <Spacer />
                     <WrapItem>
                        {videodetail.videodetail[0].publishedAt}
                     </WrapItem>
                  </Wrap>
                  <Wrap spacing="0" fontSize="1rem" mt="0.5rem" borderBottom="1px #d9d9d9b8 solid">
                     <WrapItem>カテゴリ : </WrapItem>
                     {
                        
                     }
                     <Spacer />
                     <WrapItem>
                        {catnemes[videodetail.videodetail[0].categoryId]}
                     </WrapItem>
                  </Wrap>
                  <Wrap spacing="0" fontSize="1rem" mt="0.5rem">
                     <WrapItem>タグ・キーワード : </WrapItem>
                     <Spacer />
                     <WrapItem>
                        <Wrap>
                        {videodetail.videodetail[0].tags.length > 0 ?
                           React.Children.toArray(videodetail.videodetail[0].tags.split(",").map(val =>{
                              return (
                                 <>
                                 <WrapItem>
                                    <Button 
                                       colorScheme="facebook"
                                       borderRadius="0.5rem"
                                       fontSize="0.7rem"
                                       ml="0.3rem"
                                       mt="0.3rem"
                                       px="0.5rem"
                                       height="1.5rem"
                                       as={GatsbyLink} 
                                       // as={Link} 
                                       to={"/videolist"
                                          + "?keyword=" + encodeURIComponent(val)}
                                    >
                                       {val}
                                    </Button>
                                 </WrapItem>
                                 </>
                              )
                           })
                           )
                        :
                           ""
                        }
                        </Wrap>
                     </WrapItem>
                  </Wrap>

                  <Wrap spacing="0" fontSize="1rem" mt="0.5rem">
                     <WrapItem>説明 : </WrapItem>
                     <WrapItem>
                        <Wrap>
                           <WrapItem p="1rem" fontSize="0.8rem" wordBreak="break-all">
                              <AutoBrLinkMoreParser
                                 str={videodetail.videodetail[0].description}
                                 show={show}
                                 setShow={setShow}
                              />
                           </WrapItem>
                        </Wrap>
                     </WrapItem>
                  </Wrap>

                  
                  {/* </Box> */}
               </Box>

               <Midasi5>関連ニュース</Midasi5>
               <Box px="0.75rem" display="flex" flexWrap="wrap">
                  {kanrenPosts.finished ? 
                     kanrenPosts.kanrenPosts.data ?
                        React.Children.toArray(kanrenPosts.kanrenPosts.data.posts.nodes.map((node,index) => {
                        
                           return (
                              <Box bg="#fff" mb="1rem" w="50%" verticalAlign="top" boxSizing="border-box" 
                                 borderRight={(index+1)%2 == 0 ?"none":"0.375rem solid #eee"}
                                 borderLeft={(index+1)%2 == 0 ?"0.375rem solid #eee":"none"}
                              >
                                 <Link as={GatsbyLink} to={node.uri}>
                                    <Image 
                                       w="100%" 
                                       verticalAlign="bottom" 
                                       src={node.featuredImage ? node.featuredImage.node.mediaItemUrl : ""}
                                       fallbackSrc="https://via.placeholder.com/337x190" 
                                    />
                                    <Text
                                       py="1.25rem"
                                       px="1rem"
                                       bg="#fff"
                                       fontSize="1.0rem"
                                       fontWeight="bold"
                                       lineHeight="1.5rem"
                                       boxSizing="border-box"
                                       wordBreak="break-all"
                                    >
                                       {node.title}
                                    </Text>
                                 </Link>
                              </Box>
                           )
                        }))
                     :
                        <Box>
                           <Text>該当する関連ニュースはありません</Text>
                        </Box>
                  :
                     ""
                  }

                  <Text w="100%"
                     rounded="sm"
                     fontSize="1.3rem"
                     fontWeight="bold"
                     lineHeight="4rem"
                     textAlign="center"
                     color="#fff"
                     bg="linkedin.800"
                     position="relative"
                     _before={{
                        content:'""',
                        display:"block",
                        position:"absolute",
                        top:"1.6rem",
                        right:"2rem",
                        w:"1rem",
                        h:"1rem",
                        borderTop:"0.2rem solid #fff",
                        borderRight:"0.2rem solid #fff",
                        transform:"rotate(45deg)"
                     }}
                     as={GatsbyLink}
                     to="/category/news/1"
                  >もっと見る</Text>
               </Box> 
               </>
            :
               <Text>DBに登録されていません</Text>
         :
            <Spinner m="5" />
         }
      </Layout>
   )
}
export default VideoDetail;